<template>
  <div class="role-container" v-track="{triggerType:'browse',currentUrl: $route.fullPath,behavior:'浏览角色管理页面',actionType:'进入角色管理页面'}">
    <!--查询区域start-->
    <div class="search-area">
      <div class="form-area">
        <el-form class="search-form" ref="search-form" :model="searchForm" :rules="searchFormRules" label-width="100px" >
          <el-form-item label="角色名称：" prop="roleName">
            <el-input v-model="searchForm.roleName"></el-input>
          </el-form-item>
          <el-form-item label="权限字符：" prop="permissions">
            <el-input v-model="searchForm.permissions"></el-input>
          </el-form-item>
          <el-form-item label="状态：" prop="status">
            <el-select v-model="searchForm.status" placeholder="请选择">
              <el-option label="全部" value=""></el-option>
              <el-option label="启用" value="1"></el-option>
              <el-option label="停用" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="创建时间：" prop="createTime">
            <el-date-picker
              v-model="searchForm.createTime"
              type="daterange"
              align="right"
              unlink-panels
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <div class="search-btn-area">
        <el-button type="primary" class="search-btn" @click="searchBtn">查询</el-button>
        <el-button class="reset-btn" @click="resetBtn">重置</el-button>
      </div>
    </div>
    <!--查询区域end-->

    <!-- 表格区域start -->
    <div class="table-area">
      <div class="operate-btn">
        <el-button type="primary" class="add-btn" @click="addDialogOpen"><i class="el-icon-plus"></i>新增角色</el-button>
      </div>
      <el-table
        ref="resultTable"
        :data="tableList"
        tooltip-effect="dark"
        v-loading="tableLoading">
        <el-table-column label="角色名称" min-width="220">
          <template v-slot="scope">
            {{ scope.row.roleName }}
          </template>
        </el-table-column>
        <el-table-column label="权限字符" min-width="220">
          <template v-slot="scope">
            {{ scope.row.permissions }}
          </template>
        </el-table-column>
        <el-table-column label="状态" min-width="120" show-overflow-tooltip>
          <template v-slot="scope">
            <el-switch
              v-model="scope.row.status"
              active-value="1"
              inactive-value="0"
              @change="shiftStatus(scope.row.id,$event)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" min-width="220">
          <template v-slot="scope">
            {{ scope.row.createTime }}
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="240">
          <template v-slot="scope">
            <el-button type="text" @click="editBtn(scope.row)">修改</el-button>
            <el-button v-show="scope.row.status==='0'" type="text" style="color: #f56c6c"
                       @click="delBtn(scope.row.id)">删除
            </el-button>
            <el-button v-show="scope.row.status==='1'" type="text" style="color: #808080" disabled="disabled">删除
            </el-button>
            <el-button type="text" @click="openGrantUserList(scope.row)">已分配用户</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 表格区域end -->

    <!--新增角色弹窗start-->
    <el-dialog title="新增角色" :visible.sync="addRoleDialogFlag" width="600px" class="add-role-form" >
      <el-form ref="add-role-form" label-position="right" :model="addRoleForm" label-width="120px" :rules="addRoleFormRules">
        <el-form-item label="角色名称：" prop="roleName">
          <el-input v-model="addRoleForm.roleName"></el-input>
        </el-form-item>
        <el-form-item label="权限字符：" prop="permissions">
          <el-input v-model="addRoleForm.permissions"></el-input>
        </el-form-item>
        <el-form-item label="授权菜单：" prop="menu">
          <div style="height: 120px;overflow-y: auto">
            <el-tree
              ref="menu-tree-for-add"
              node-key="id"
              default-expand-all
              :data="menuList"
              :props="menuListTreeProps"
              show-checkbox>
            </el-tree>
          </div>
        </el-form-item>
        <el-form-item label="角色状态：" prop="status">
          <el-switch
            v-model="addRoleForm.status"
            active-color="#13ce66"
            inactive-color="#87939a"
            active-value="1"
            inactive-value="0">
          </el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addRoleDialogFlag = false">取 消</el-button>
        <el-button type="primary" @click="addRoleSure">确 定</el-button>
      </div>
    </el-dialog>
    <!--新增角色弹窗end-->

    <!--编辑角色弹窗start-->
    <el-dialog title="编辑角色" :visible.sync="editRoleDialogFlag" width="600px">
      <el-form ref="edit-role-form" label-position="right" :model="editRoleForm" label-width="120px" :rules="addRoleFormRules">
        <el-form-item label="角色名称：" prop="roleName">
          <el-input v-model="editRoleForm.roleName"></el-input>
        </el-form-item>
        <el-form-item label="权限字符：" prop="permissions">
          <el-input v-model="editRoleForm.permissions"></el-input>
        </el-form-item>
        <el-form-item label="授权菜单：" prop="permission">
          <div style="height: 120px;overflow-y: auto">
            <el-tree
              ref="menu-tree-for-edit"
              node-key="id"
              :data="menuList"
              :props="menuListTreeProps"
              default-expand-all
              show-checkbox>
            </el-tree>
          </div>
        </el-form-item>
        <el-form-item label="角色状态：" prop="status">
          <el-switch
            v-model="editRoleForm.status"
            active-color="#13ce66"
            inactive-color="#87939a"
            active-value="1"
            inactive-value="0">
          </el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editRoleDialogFlag = false">取 消</el-button>
        <el-button type="primary" @click="editRole" :loading="editBtnLoading">确 定</el-button>
      </div>
    </el-dialog>
    <!--编辑角色弹窗end-->

    <!--角色分配弹窗start-->
    <el-dialog title="已分配用户" :visible.sync="grantRoleDialogFlag" width="700px" class="grantDialog">
      <div class="table-area">
        <div class="operate-btn">
          <el-button type="danger" size="mini" @click="removeUserList">批量移除</el-button>
        </div>
        <el-table
          ref="multipleTable"
          :data="userList"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange">
          <el-table-column
            type="selection"
            width="55">
          </el-table-column>
          <el-table-column
            label="用户账号"
            prop="account"
            min-width="120">
          </el-table-column>
          <el-table-column
            prop="nickname"
            label="用户昵称"
            min-width="120">
          </el-table-column>
          <el-table-column
            prop="mobilePhone"
            label="手机号"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            label="操作">
            <template v-slot="scope">
              <el-button type="text" size="mini" style="color: #ff4d4f;" @click="removeUser(scope)">移除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="display: flex;justify-content: flex-end">
          <tg-pagination :total="paginationConfig.total" :page.sync="paginationConfig.currentPage"
                         :limit.sync="paginationConfig.pageNum"
                         @pagination="getGrantUserList"></tg-pagination>
        </div>
      </div>
    </el-dialog>
    <!--编辑角色弹窗end-->

  </div>
</template>

<script>
import textValidator from '@/util/validate/text-validator'
import { listMenu } from '@/service/api/system/menu'
import { handleTree } from '@/util/normal'
import { addRole, listRole, getRole, updateRole, shiftRoleStatus, delRole } from '@/service/api/system/role'

export default {
  name: 'UserRole',
  data() {
    const checkRoleName = (rules, value, callback) => {
      textValidator.checkTextName(true, '角色名称', rules, value, callback, 2, 20)
    }
    const checkAuthString = (rules, value, callback) => {
      textValidator.checkTextNumber(true, '权限字符', rules, value, callback, 2, 20)
    }
    const checkRoleNameForSearch = (rules, value, callback) => {
      textValidator.checkTextName(false, '角色名称', rules, value, callback, 2, 20)
    }
    const checkAuthStringForSearch = (rules, value, callback) => {
      textValidator.checkTextNumber(false, '权限字符', rules, value, callback, 2, 20)
    }
    return {
      // --查询区域
      // 查询表单
      searchForm: {
        roleName: '',
        permissions: '',
        status: '',
        createTime: []
      },
      // 查询表单校验规则
      searchFormRules: {
        roleName: [
          {
            validator: checkRoleNameForSearch,
            trigger: 'change'
          }
        ],
        permissions: [
          {
            validator: checkAuthStringForSearch,
            trigger: 'change'
          }
        ]
      },

      // 日期选择快捷键
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      // --表格区域
      // 表格数据
      tableList: [],
      // 表格加载
      tableLoading: false,

      // --- 弹窗区域 ---
      // -- 新增弹窗
      // 新增弹窗显示控制
      addRoleDialogFlag: false,
      // 菜单表格树数据
      menuList: [],
      // 树状列表映射
      menuListTreeProps: {
        label: 'menuName'
      },
      // 新增弹窗表单
      addRoleForm: {
        roleName: '',
        permissions: '',
        menus: '',
        status: '1'
      },
      // 新增弹窗校验规则
      addRoleFormRules: {
        roleName: [
          {
            required: true,
            validator: checkRoleName,
            trigger: 'change'
          }
        ],
        permissions: [
          {
            required: true,
            validator: checkAuthString,
            trigger: 'change'
          }
        ]
      },

      // -- 编辑弹窗
      // 编辑弹窗显示控制
      editRoleDialogFlag: false,
      // 编辑弹窗确认按钮加载动画
      editBtnLoading: false,
      // 编辑弹窗表单
      editRoleForm: {
        roleName: '',
        permissions: '',
        permission: '',
        status: '1'
      },

      // -- 已分配用户弹窗
      // 已分配用户弹窗显示控制
      grantRoleDialogFlag: false,
      // 批量选择
      multipleSelection: [],
      // 用户列表
      userList: [],
      // 分页配置
      paginationConfig: {
        currentPage: 1,
        pageNum: 10,
        total: 10
      }
    }
  },
  mounted() {
    // 获取角色列表数据
    this.getRoleTableList()
    this.getList()
  },
  methods: {
    /** 初始化菜单列表 */
    getList() {
      listMenu().then(res => {
        if (res.data.status === 200) {
          this.menuList = handleTree(res.data.data, 'id')
        }
      }).catch(() => {
      })
    },
    // 获取角色列表
    getRoleTableList() {
      const params = Object.assign({}, this.searchForm, {
        startTime: this.searchForm.createTime[0] ? this.searchForm.createTime[0] : '',
        endTime: this.searchForm.createTime[1] ? this.searchForm.createTime[1] : ''
      })
      delete params.createTime
      this.tableLoading = true
      listRole(params).then(res => {
        if (res.data.status === 200) {
          this.tableList = res.data.data
          console.log(this.tableList)
        }
        this.tableLoading = false
      }).catch(err => {
        console.log(err)
        this.tableLoading = false
      })
    },
    // 查询按钮
    searchBtn() {
      this.$refs['search-form'].validate(valid => {
        if (valid) {
          this.getRoleTableList()
        }
      })
    },
    // 重置按钮
    resetBtn() {
      this.$refs['search-form'].resetFields()
      this.getRoleTableList()
    },
    // 打开新增弹窗
    addDialogOpen() {
      const that = this
      this.addRoleDialogFlag = true
      this.$nextTick(() => {
        that.$refs['add-role-form'].resetFields()
      })
    },
    // 确认新增角色
    addRoleSure() {
      this.$refs['add-role-form'].validate(valid => {
        if (valid) {
          this.addRoleForm.menus = this.$refs['menu-tree-for-add'].getCheckedKeys()
          addRole(this.addRoleForm).then(res => {
            if (res.data.status === 200) {
              this.$message.success('新增成功')
            } else {
              this.$message.error('新增失败')
            }
            this.addRoleDialogFlag = false
          }).catch(err => {
            console.log(err)
            this.addRoleDialogFlag = false
            this.$message.error('新增失败')
          })
        }
      })
    },
    // 切换状态
    shiftStatus(id, val) {
      const data = {
        id: id,
        status: val
      }
      shiftRoleStatus(data).then(res => {
        if (res.data.status === 200) {
          this.$message.success('状态修改成功')
        } else {
          this.$message.error('状态修改失败')
          this.getRoleTableList()
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('状态修改失败')
        this.getRoleTableList()
      })
    },
    // 编辑按钮
    editBtn(role) {
      const that = this
      getRole({ id: role.id }).then(res => {
        if (res.data.status === 200) {
          this.editRoleForm = res.data.data
        }
      }).finally(() => {
        this.editRoleDialogFlag = true
        this.$nextTick(() => {
          that.$refs['menu-tree-for-edit'].setCheckedKeys(this.editRoleForm.menus)
        })
      })
    },
    // 确认编辑
    editRole() {
      this.$refs['edit-role-form'].validate(valid => {
        if (valid) {
          this.editBtnLoading = true
          this.editRoleForm.menus = this.$refs['menu-tree-for-edit'].getCheckedKeys()
          delete this.editRoleForm.createBy
          delete this.editRoleForm.createTime
          console.log(this.editRoleForm)
          updateRole(this.editRoleForm).then(res => {
            if (res.data.status === 200) {
              this.$message.success('编辑成功')
            } else {
              this.$message.error('编辑失败')
            }
            this.editRoleDialogFlag = false
            this.editBtnLoading = false
            this.resetBtn()
          }).catch(err => {
            console.log(err)
            this.$message.error('编辑失败')
            this.editRoleDialogFlag = false
            this.editBtnLoading = false
            this.resetBtn()
          })
        }
      })
    },
    // 删除按钮
    delBtn(id) {
      this.$confirm('是否确认删除此角色?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const data = { id: id }
        delRole(data).then(res => {
          if (res.data.status === 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
          } else {

          }
        }).catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '删除失败'
          })
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 打开已分配用户弹窗
    openGrantUserList(role) {
      this.getGrantUserList()
      this.grantRoleDialogFlag = true
    },
    // 获取指定角色已分配的用户
    getGrantUserList() {
      this.userList = [
        {
          userId: 'ToguideDev001',
          account: 'admin123',
          nickname: 'HackerAC',
          mobilePhone: '17388888888',
          email: 'hackerac@163.com',
          createData: '2022-04-22',
          status: '1'
        },
        {
          userId: 'ToguideDev001',
          account: 'admin123',
          nickname: 'HackerAC',
          mobilePhone: '17388888888',
          email: 'hackerac@163.com',
          createData: '2022-04-22',
          status: '1'
        },
        {
          userId: 'ToguideDev001',
          account: 'admin123',
          nickname: 'HackerAC',
          mobilePhone: '17388888888',
          email: 'hackerac@163.com',
          createData: '2022-04-22',
          status: '1'
        },
        {
          userId: 'ToguideDev001',
          account: 'admin123',
          nickname: 'HackerAC',
          mobilePhone: '17388888888',
          email: 'hackerac@163.com',
          createData: '2022-04-22',
          status: '1'
        },
        {
          userId: 'ToguideDev001',
          account: 'admin123',
          nickname: 'HackerAC',
          mobilePhone: '17388888888',
          email: 'hackerac@163.com',
          createData: '2022-04-22',
          status: '1'
        },
        {
          userId: 'ToguideDev001',
          account: 'admin123',
          nickname: 'HackerAC',
          mobilePhone: '17388888888',
          email: 'hackerac@163.com',
          createData: '2022-04-22',
          status: '1'
        },
        {
          userId: 'ToguideDev001',
          account: 'admin123',
          nickname: 'HackerAC',
          mobilePhone: '17388888888',
          email: 'hackerac@163.com',
          createData: '2022-04-22',
          status: '1'
        },
        {
          userId: 'ToguideDev001',
          account: 'admin123',
          nickname: 'HackerAC',
          mobilePhone: '17388888888',
          email: 'hackerac@163.com',
          createData: '2022-04-22',
          status: '1'
        },
        {
          userId: 'ToguideDev001',
          account: 'admin123',
          nickname: 'HackerAC',
          mobilePhone: '17388888888',
          email: 'hackerac@163.com',
          createData: '2022-04-22',
          status: '1'
        },
        {
          userId: 'ToguideDev001',
          account: 'admin123',
          nickname: 'HackerAC',
          mobilePhone: '17388888888',
          email: 'hackerac@163.com',
          createData: '2022-04-22',
          status: '1'
        }
      ]
      this.paginationConfig.total = 10
    },
    // 多选处理
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 批量移除已分配的用户
    removeUserList() {
      this.grantRoleDialogFlag = false
    },
    // 移除已分配的用户
    removeUser() {

    },
    // 授权菜单点击
    handleCheckMenu(val, type) {
      console.log(type)
      console.log(val)
    }
  }
}
</script>

<style scoped lang="scss">
@import "~$assets/css/common/search-area.scss";
@import "~$assets/css/common/table-area.scss";
.role-container{
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  ::v-deep.el-scrollbar__wrap {
      overflow-x: hidden;
      overflow-y: auto;
  }
  .add-role-form{
    ::v-deep.el-dialog__body{
      //padding-right: 50px;
    }
  }

  .grantDialog{
    ::v-deep.el-dialog__body{
      padding: 0;
    }
  }
}
</style>
